<template>
  <v-card>
    <v-btn color="green" block text class="white--text" @click="exportExcel"
      >Exportar a Excel <v-icon right>mdi-file-excel</v-icon>
    </v-btn>
    <v-card-title>
      <v-container fluid>
        <v-row class="pa-0">
          {{ items.titulo }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
            class="pa-0"
          ></v-text-field>
        </v-row>
        <v-row class="pt-3">
          <v-switch
            v-model="toSelectMany"
            label="¿Seleccionar varios?"
            class="pa-0"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            rounded
            v-if="toSelectMany"
            @click="showDialogEliminarVarios"
            >Eliminar seleccionados</v-btn
          >
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
      :show-select="toSelectMany"
      v-model="selectedItems"
      :loading="items.garantias.length > 0 ? false : true"
      :headers="items.headers"
      :item-key="getWhichId"
      :items="items.garantias"
      :search="search"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          class="mr-2"
          v-if="item.imagen_falla !== 'undefined' && item.imagen_falla"
          @click="mostrarImagen(item)"
        >
          mdi-image
        </v-icon>
        <v-icon class="mr-2" @click="masInfo(item)">
          mdi-eye
        </v-icon>
        <v-icon
          class="mr-2"
          @click="(dialogEliminar = true), (itemPorEliminar = item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogImagen" max-width="600px">
      <v-card>
        <v-carousel>
          <v-carousel-item
            v-for="(item, i) in selectedImage"
            :key="i"
            :src="item"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInfo" max-width="100%" scrollable>
      <v-card>
        <v-card-title color="#0062B0" class="headline primary white--text">
          Detalle
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text scrollable>
          <v-container>
            <v-row>
              <v-col
                cols="6"
                v-for="(item, index) in selectedItem"
                :key="index"
                class="pt-0 pb-0"
              >
                <h3 style="color: #808080;">
                  {{
                    index
                      .toUpperCase()
                      .split("_")
                      .join(" ")
                  }}
                </h3>
                <v-text-field
                  :label="obtenerLabel(item)"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="pt-0 pb-0"
                v-for="(item, index) in selectedNumsPartes"
                :key="index"
              >
                <h3 style="color: #808080;">
                  {{ "Numero parte " + (index + 1) }}
                </h3>
                <v-text-field
                  :label="item !== null ? item.toString() : 'Sin datos'"
                  :disabled="true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogInfo = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog de eliminar -->
    <v-dialog v-model="dialogEliminar" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar el registro?
        </v-card-title>
        <!-- <v-card-text> </v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogEliminar = false">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="eliminar">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog para eliminar varios -->
    <v-dialog v-model="dialogEliminarVarios" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar
          {{
            this.selectedItems.length > 1
              ? this.selectedItems.length + " registros seleccionados"
              : "el registro seleccionado"
          }}?
        </v-card-title>
        <!-- <v-card-text> </v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogEliminarVarios = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="eliminarVarios">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingMultiple" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Eliminando: {{ textLoading }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import XLSX from "xlsx";
import FileSaver from "file-saver";
import PostService from "../../PostService";
export default {
  props: ["items"],
  data: () => ({
    selectedItems: [],
    textLoading: "",
    search: "",
    dialogInfo: false,
    dialogImagen: false,
    selectedImage: "",
    selectedItem: null,
    toSelectMany: false,
    dialogEliminarVarios: false,
    selectedNumsPartes: [],
    loadingMultiple: false,
    urlImgs: process.env.VUE_APP_API_URL + "uploads/" || "/uploads/",
    itemPorEliminar: null,
    dialogEliminar: false
  }),
  computed: {
    getWhichId() {
      if (this.items.titulo == "Garantías activadas") {
        return "id_formato";
      } else if (this.items.titulo == "Experiencia de usuario") {
        return "id_experiencia";
      } else {
        return "id_activar";
      }
    }
  },
  methods: {
    exportExcel() {
      const wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Export EXCEL",
        Subject: "Export",
        Author: "Bajaj",
        CreatedDate: new Date()
      };
      wb.SheetNames.push("Test Sheet");
      const ws = XLSX.utils.json_to_sheet(this.items.garantias);
      wb.Sheets["Test Sheet"] = ws;
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      FileSaver.saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "exportar-" + this.items.titulo + ".xlsx"
      );
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      const view = new Uint8Array(buf); //create uint8array as viewer
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    },
    mostrarImagen(item) {
      this.dialogImagen = true;
      this.selectedImage = item.imagen_falla.split(",");
    },
    masInfo(item) {
      this.selectedItem = item;
      if (item.numero_parteuno)
        this.selectedNumsPartes = item.numero_parteuno.split(",");
      this.dialogInfo = true;
    },
    async eliminar() {
      let idItemEliminar = "";
      let nombreTabla = "";
      let nombreDeId = "";
      if (this.items.titulo == "Garantías activadas") {
        idItemEliminar = this.itemPorEliminar.id_formato;
        nombreTabla = "formato_garantia";
        nombreDeId = "id_formato";
      } else if (this.items.titulo == "Experiencia de usuario") {
        idItemEliminar = this.itemPorEliminar.id_experiencia;
        nombreTabla = "experiencia_usuario";
        nombreDeId = "id_experiencia";
      } else if (this.items.titulo == "Garantías") {
        idItemEliminar = this.itemPorEliminar.id_activar;
        nombreTabla = "activa_garantia";
        nombreDeId = "id_activar";
      }
      await PostService.borrarRegistro(idItemEliminar, nombreTabla, nombreDeId);
      this.$emit("actualizar");
      this.dialogEliminar = false;
      this.$store.dispatch("setSnackBarProps", {
        mostrar: true,
        mensaje: "Se ha eliminado con éxito.",
        color: "success"
      });
    },
    obtenerLabel(item) {
      const label =
        item === 1
          ? "Sí"
          : item === 0
          ? "No"
          : item != null
          ? item.toString()
          : "Sin datos";
      return label;
    },
    async eliminarVarios() {
      this.loadingMultiple = true;
      let contadorEliminados = 0;
      for (const item of this.selectedItems) {
        contadorEliminados++;
        this.textLoading = `${contadorEliminados}/${this.selectedItems.length}`;
        await this.eliminarUno(item);
      }
      this.loadingMultiple = false;
      this.selectedItems = [];
      this.dialogEliminarVarios = false;
      this.$emit("actualizar");
    },
    async eliminarUno(itemPorEliminar) {
      let idItemEliminar = "";
      let nombreTabla = "";
      let nombreDeId = "";
      if (this.items.titulo == "Garantías activadas") {
        idItemEliminar = itemPorEliminar.id_formato;
        nombreTabla = "formato_garantia";
        nombreDeId = "id_formato";
      } else if (this.items.titulo == "Experiencia de usuario") {
        idItemEliminar = itemPorEliminar.id_experiencia;
        nombreTabla = "experiencia_usuario";
        nombreDeId = "id_experiencia";
      } else if (this.items.titulo == "Garantías") {
        idItemEliminar = itemPorEliminar.id_activar;
        nombreTabla = "activa_garantia";
        nombreDeId = "id_activar";
      }
      await PostService.borrarRegistro(idItemEliminar, nombreTabla, nombreDeId);
      this.$store.dispatch("setSnackBarProps", {
        mostrar: true,
        mensaje: "Se han eliminado con éxito.",
        color: "success"
      });
    },
    showDialogEliminarVarios() {
      if (this.selectedItems.length < 1) {
        this.dialogEliminarVarios = false;
        return this.$store.dispatch("setSnackBarProps", {
          mostrar: true,
          mensaje: "Tiene que seleccionar al menos un ítem para eliminar.",
          color: "error"
        });
      }
      this.dialogEliminarVarios = true;
    }
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important ;
}
</style>
